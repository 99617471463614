<template>
<div class="template-full-width">
  <br /><br /><br /><br />
  <div v-if="!loaded">Détection de votre appareil en cours ...</div>
  <div v-else>{{device}}</div>
  
  <br /><br /><br /><br />
</div>

    <br /><br /><br /><br />
    <Footer v-if="appView==false" :language="language" :displayModalMenu="displayModalMenu" />

  
</template>

<script>

import Footer from '../components/layout/Footer.vue'
import jQuery  from 'jquery';
import { APIRequest } from "@simb2s/senseye-sdk"
import CGVmenu from "../components/children/CGV/CgvMenuComponent.vue"
import { useMeta } from 'vue-meta'

export default {
  name: 'IdentifyDevice',
  components: {
    Footer,
    CGVmenu,
  },
  data() {
    return {
     device:null,
     loaded:false,
     device_identification:null
    }
    },
   
    props:["displayModalMenu","language","appView"],
     watch:{
        
        
        
        },


      created() {

        if(this.language=="fr")
    {

    
      useMeta({
    title: "VISAGES - Conditions générales de vente",
    
    htmlAttrs: { lang: this.language, amp: true },
    
})

document.querySelector('meta[name="description"]').setAttribute('content',"Conditions générales de vente VISAGES.")
    }
    else
    {

      useMeta({
    title: "VISAGES - Conditions",
    
    htmlAttrs: { lang: this.language, amp: true },
    
})
   
document.querySelector('meta[name="description"]').setAttribute('content',"VISAGES Conditions.")
    }

},
methods:{
  getExactAppleDeviceModel() {
    const ua = navigator.userAgent;

    // Vérifie si c'est un appareil Apple (iPhone ou iPad)
    if (!/iPhone|iPad/.test(ua)) return "Modèle autre";

    // Obtenir la résolution de l'écran en points CSS
    const width = screen.width * window.devicePixelRatio;
    const height = screen.height * window.devicePixelRatio;

    // Liste des résolutions connues pour iPhone et iPad
    const devices = {
          "iPhone (1st gen), 3G, 3GS": { width: 320, height: 480 },
          "iPhone 4, 4S": { width: 640, height: 960 },
          "iPhone 5, 5C, 5S, SE (1st gen)": { width: 640, height: 1136 },
          "iPhone SE (2nd/3rd gen), iPhone 6/7/8": { width: 750, height: 1334 },
          "iPhone 6+/7+/8+": { width: 1080, height: 1920 },
          "iPhone X, XS, 11 Pro": { width: 1125, height: 2436 },
          "iPhone XR, 11": { width: 828, height: 1792 },
          "iPhone XS Max, 11 Pro Max": { width: 1242, height: 2688 },
          "iPhone 12 Mini, 13 Mini": { width: 1080, height: 2340 },
          "iPhone 12/12 Pro, 13/13 Pro, 14, 15": { width: 1170, height: 2532 },
          "iPhone 12 Pro Max, 13 Pro Max, 14 Plus, 15 Plus": { width: 1284, height: 2778 },
          "iPhone 14 Pro, 15 Pro, 16": { width: 1179, height: 2556 },
          "iPhone 14 Pro Max, 15 Pro Max, 16 Plus": { width: 1290, height: 2796 },
          "iPhone 16 Pro, 16 Pro Max": { width: 1320, height: 2868 }, // (hypothétique)
          "iPad Mini (1st-4th gen)": { width: 768, height: 1024 },
          "iPad Mini (5th gen), iPad Air (1st-2nd gen)": { width: 1536, height: 2048 },
          "iPad Mini (6th gen),": { width: 1488, height: 2266 },
          "iPad (7th-9th gen)": { width: 1620, height: 2160 },
          "iPad Air (4th-5th gen)": { width: 1640, height: 2360 },
          "iPad Pro 9.7\"": { width: 1536, height: 2048 },
          "iPad Pro 10.5\"": { width: 1668, height: 2224 },
          "iPad Pro 11\" (1st-4th gen)": { width: 1668, height: 2388 },
          "iPad Pro 12.9\" (3rd-6th gen)": { width: 2048, height: 2732 },
      };

    // Trouver le modèle correspondant à la résolution détectée
    for (const [model, res] of Object.entries(devices)) {
        if ((width === res.width && height === res.height) || (width === res.height && height === res.width)) {
            return model;
        }
    }

    return "Modèle d'iPhone inconnu";
}
},

  async mounted()
  {
    this.loaded=false
    this.device_identification=  await APIRequest.detail('device-identifications',this.$route.params.id)

    
    console.log(this.getExactAppleDeviceModel())
     this.device = this.getExactAppleDeviceModel()

     try {
      let params={
        device:this.device,
        status:"ok"
      }
      this.device_identification=await APIRequest.update('device-identifications',this.$route.params.id,params)
      this.loaded=true

     console.log(this.device_identification.id)
      
     } catch (error) {
      
      alert('Une erreur est survenue.')
      this.loaded=true
     }
  }
  
}
</script>

<style scoped>

</style>